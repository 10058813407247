// Generated by Framer (f4b44ab)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./hu2InyEvT-0.js";

const cycleOrder = ["TxpNjW9If", "e7l2iT9JZ"];

const serializationHash = "framer-Hi6Vx"

const variantClassNames = {e7l2iT9JZ: "framer-v-35qzhh", TxpNjW9If: "framer-v-1slkz3a"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {VMMHDo1EA: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant mobile": "e7l2iT9JZ", desktop: "TxpNjW9If"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "TxpNjW9If"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "TxpNjW9If", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-Hi6Vx", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1slkz3a", className)} data-framer-name={"desktop"} layoutDependency={layoutDependency} layoutId={"TxpNjW9If"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 6, borderBottomRightRadius: 6, borderTopLeftRadius: 6, borderTopRightRadius: 6, ...style}} {...addPropertyOverrides({e7l2iT9JZ: {"data-framer-name": "Variant mobile"}}, baseVariant, gestureVariant)}/></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-Hi6Vx [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Hi6Vx .framer-5a2lf7 { display: block; }", ".framer-Hi6Vx .framer-1slkz3a { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 445px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Hi6Vx .framer-1slkz3a { gap: 0px; } .framer-Hi6Vx .framer-1slkz3a > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-Hi6Vx .framer-1slkz3a > :first-child { margin-top: 0px; } .framer-Hi6Vx .framer-1slkz3a > :last-child { margin-bottom: 0px; } }", ".framer-Hi6Vx.framer-v-35qzhh .framer-1slkz3a { width: 323px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 49
 * @framerIntrinsicWidth 445
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"e7l2iT9JZ":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 */
const Framerhu2InyEvT: React.ComponentType<Props> = withCSS(Component, css, "framer-Hi6Vx") as typeof Component;
export default Framerhu2InyEvT;

Framerhu2InyEvT.displayName = "Elements / Input Copy";

Framerhu2InyEvT.defaultProps = {height: 49, width: 445};

addPropertyControls(Framerhu2InyEvT, {variant: {options: ["TxpNjW9If", "e7l2iT9JZ"], optionTitles: ["desktop", "Variant mobile"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerhu2InyEvT, [])